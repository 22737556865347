import React from 'react';
import { Helmet } from 'react-helmet';
import Head from '../components/head';
import Layout from '../components/layout';
import BookCollection from '../components/bookCollection';


const BooksIndexPage = () => {
  const PAGE = "Books";
  const baseSiteUrl = 'https://authorseanallen.com';

  return (
    <Layout>
      <Head title={PAGE} />
      <Helmet>
        <meta name="description"
          content="Books by the genre fiction author Sean Allen." />
        <link rel="canonical" href={`${baseSiteUrl}/books`} />
      </Helmet>
      <BookCollection series="The D-Evolution" />
    </Layout>
  )
}

export default BooksIndexPage;