import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { booksMeta } from '../lib/booksMeta';

const Book = ({ bookName }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    loadImage();
  }, []);

  const book = booksMeta[bookName];

  const loadImage = () => {
    if (imageLoaded) return;

    const imageUrl = require(`../images/coverThumbNails/${book.thumbImg}`);

    let blockToBlur = document.querySelector(`.${book.title}`);

    let imgContainer = document.querySelector(`.${book.title}-cover-img-container`);

    if (!imgContainer) return;

    blockToBlur.classList.add("loading");
    let preloadedCover = document.createElement("img");
    preloadedCover.src = imageUrl;
    preloadedCover.alt = `${book.title} book cover`;


    preloadedCover.addEventListener('load', () => {
      blockToBlur.classList.remove("loading");
      imgContainer.appendChild(preloadedCover);
    });

    blockToBlur.removeEventListener('load', () => console.log('Aw, Yeah!'));

    setImageLoaded(true);
  }

  return (
    <div className={`${book.title} book-container loading`}>
      <h1 className="book-in-series-title">{book.numberInSeries}</h1>
      <div>
        <Link to={`/${book.title}`}>
          <div className={`${book.title}-cover-img-container loading`}>
            {/* //* img dynamically inserted for blur-up */}
          </div>
        </Link>
      </div>
    </div>
  )
}

export default Book;