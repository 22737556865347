import React from 'react';
import Book from '../components/book';

const BookCollection = (props) => {
  return (
    <React.Fragment>
      <section className="BookCollection hero dark">
        <div className="page-header-info">
          <h3 className="page-title">Books by Sean</h3>
          <hr />
        </div>
        <h3 className="series-title hero-title">{props.series}</h3>
        <div className="grid-container">
          <div className="book-collection-grid">
            <Book bookName="Deceit" />
            <Book bookName="Decline" />
            <Book bookName="Dishonor" />
            <Book bookName="Deicide" />
            <Book bookName="Downfall" />
          </div>
        </div>
        <hr />
      </section>

    </React.Fragment>
  )
}

export default BookCollection;
